<template>
    <div class="card">
        <div class="card-header">
            <div class="d-flex align-items-start">
                <div class="d-flex align-items-start">
                    <div class="avatar me-3">
                        <span class="avatar-initial rounded-circle bg-label-success">{{ item.project_shortname.slice(0, 2)
                        }}</span>
                    </div>
                    <div class="me-2">
                        <h5 class="mb-1"><a href="#" @click.prevent="clickShow(item)" class="h5 stretched-link">{{
                            item.project_shortname }}</a></h5>
                        <div class="client-info d-flex align-items-center">
                            <h6 class="mb-0 me-1"></h6>
                            <span>{{ getClient(item.client_id) }}</span>
                        </div>
                    </div>
                </div>
                <div class="ms-auto">
                    <div class="dropdown zindex-2">
                        <button type="button" class="btn dropdown-toggle hide-arrow p-0" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end" style="">
                            <li><router-link class="dropdown-item" :to="'/project/' + item.project_id + '/edit'">Edytuj
                                    projekt</router-link></li>
                            <li><router-link class="dropdown-item" :to="'/project/' + item.project_id + '/summary'">Oś
                                    czasu</router-link></li>
                            <li><router-link class="dropdown-item"
                                    :to="'/project/' + item.project_id + '/task'">Zadania</router-link></li>
                            <li><router-link class="dropdown-item" :to="'/board/' + item.project_id">Artboard</router-link>
                            </li>
                            <li><router-link class="dropdown-item"
                                    :to="'/project/' + item.project_id + '/file'">Pliki</router-link></li>
                            <li><router-link class="dropdown-item"
                                    :to="'/project/' + item.project_id + '/calendar'">Kalendarz</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <!-- <div class="d-flex align-items-center flex-wrap">
                <div class="bg-lighter p-2 rounded me-auto mb-3">
                    <h6 class="mb-1">$24.8k <span class="text-body fw-normal">/ $18.2k</span></h6>
                    <span>Total Budget</span>
                </div>
                <div class="text-end mb-3">
                    <h6 class="mb-1">Start Date: <span class="text-body fw-normal">{{ item.project_created_at }}</span></h6>
                    <h6 class="mb-1">Deadline: <span class="text-body fw-normal">{{ item.project_created_at }}</span></h6>
                </div>
            </div> -->
            <p class="mb-0">{{ item.project_name }}</p>
        </div>
        <div class="card-body border-top">
            <div class="d-flex align-items-center mb-3">
                <!-- <h6 class="mb-1">All Hours: <span class="text-body fw-normal">380/244</span></h6> -->
                <span class="badge bg-label-success ms-auto">Zostało {{ item.tasks.count_all - item.tasks.count_done }}
                    zadań</span>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-1">
                <small>Postęp: {{ item.tasks.count_done }}/{{ item.tasks.count_all }}</small>
                <small v-if="item.tasks.count_all > 0">{{ Math.round(item.tasks.count_done / item.tasks.count_all * 100) }}%
                    zakończonych</small>
                <small v-else>0% Completed</small>
            </div>
            <div class="progress mb-3" style="height: 8px">
                <div class="progress-bar" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"
                    :style="{ width: item.tasks.progress + '%' }"></div>
            </div>
            <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <ul class="list-unstyled d-flex align-items-center avatar-group mb-0 zindex-2">
                        <li v-for="user, index2 in item.users" :key="index2" is="MagicoAvatar" :size="32"
                                :user_id="user.user_id" class="pull-up">
                            </li>
                        <li><small class="text-muted">{{ item.users.lenght }}</small></li>
                    </ul>
                </div>
                <!-- <div class="ms-auto">
                    <a href="javascript:void(0);" class="text-body"><i class="bx bx-chat"></i> {{ item.tasks.count_all
                    }}</a>
                </div> -->
                <div style="display:none">
                    {{ generateTooltipZespol(item) }}
                    {{ generateTooltipEtapUmowy(item) }}
                    {{ generateTooltipEtapPracy(item) }}
                    {{ generateTooltipSettlementStage(item) }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MagicoAvatar from '../../../components/MagicoAvatar.vue'
import dayjs from 'dayjs'
export default {
    name: 'project_index_item_box',
    template: '#project_index_item_box-template',
    components: {MagicoAvatar},
    props: ['post'],
    data: function () {
        return {
            dayjs: dayjs,
            box_variant: 'primary',
            variant_class: '',
            box_url: '',

            g_slownik_contract: [],
            g_slownik_project_settlements: [],
        }
    },
    computed: {
        slownik_contest: function () { return this.$store.state.slownik_contest },
        slownik_client: function () { return this.$store.state.slownik_client },
        slownik_project_settlements: function () { return this.$store.state.slownik_project_settlements },
        slownik_project_contract: function () { return this.$store.state.slownik_project_contract },
        slownik_user_position: function () { return this.$store.state.slownik_user_position },
    },
    methods: {
        getUserImage(user_id) {
            return this.$store.state.users.find(item => user_id == item.id)?.avatar;
        },
        generateTooltipZespol() {
            var text = '';
            this.item.users.forEach(i => {
                var position = '';
                this.slownik_user_position.forEach(a => {
                    position = a.value == i.user_position ? a.name : position;
                })
                text = '' + i.name + ' (' + position + ')' + '<br />' + text;

            })
            var a = {
                title: text,
                placement: 'bottom',
                variant: 'light',
                'html': true
            }
            return a;
        },
        clickShow: function (item) {
            this.$router.push('/project/' + item.project_id + '/summary')
        },
        generateTooltipSettlementStage: function (item) {
            var text = '';
            item.settlementstages.forEach(i => {
                if (i.settlementstage_status == 0) {
                    text = text + '' + i.settlementstage_name + '' + '<br />';
                } else {
                    text = text + '<s>' + i.settlementstage_name + '</s>' + '<br />';
                }
            })
            var a = {
                title: text,
                placement: 'bottom',
                variant: 'light',
                'html': true
            }
            return a;
        },
        generateTooltipEtapPracy: function (item) {
            var text = '';
            item.workstages.forEach(i => {
                if (i.workstage_status == 0) {
                    text = text + '' + i.workstage_name + '' + '<br />';
                } else {
                    text = text + '<s>' + i.workstage_name + '</s>' + '<br />';
                }
            })
            var a = {
                title: text,
                placement: 'bottom',
                variant: 'light',
                'html': true
            }
            return a;
        },
        generateTooltipEtapRozliczenia: function (item) {
            console.log('rozliczenia', this.slownik_project_settlements)
            var text = '';
            var ar = item.project_settlements.split('|');
            this.slownik_project_settlements.forEach(i => {
                var jest = false
                ar.forEach(y => {
                    if (y == i.value) {
                        jest = true
                    }
                })
                if (jest) {
                    text = text + '<s>' + i.name + '</s>' + '<br />';
                } else {
                    text = text + '' + i.name + '' + '<br />';
                }
            })
            var a = {
                title: text,
                placement: 'bottom',
                variant: 'light',
                'html': true
            }
            return a;
        },
        generateTooltipEtapUmowy: function (item) {
            var text = '';
            var ar = item.project_contract.split('|');
            this.slownik_project_contract.forEach(i => {
                var jest = false
                ar.forEach(y => {
                    if (y == i.value) {
                        jest = true
                    }
                })
                if (jest) {
                    text = text + '<s>' + i.name + '</s>' + '<br />';
                } else {
                    text = text + '' + i.name + '' + '<br />';
                }
            })
            var a = {
                title: text,
                placement: 'bottom',
                variant: 'light',
                'html': true
            }
            return a;
        },
        getContest: function (id) {
            var name = '';
            this.slownik_contest.forEach(item => {
                if (item.contest_id == id) {
                    name = item.contest_shortname;
                    return
                }
            })
            return name;
        },
        getClient: function (id) {
            var name = '';
            this.slownik_client.forEach(item => {
                if (item.client_id == id) {
                    console.log(';', item);
                    name = item.client_name_short ? item.client_name_short : item.client_name;
                    return
                }
            })
            return name;
        },
    },
    created() {
        this.item = this.post
        this.variant_class = this.post.client_com_status == 'open' ? 'card-border-c-blue' : 'card-border-c-green';
        this.box_variant = this.post.client_com_status == 'open' ? 'primary' : 'success';
    }
}
</script>
<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row g-4 mb-4">
            <ul class="nav nav-pills flex-column flex-sm-row mb-0">
                <!-- <li class="nav-item">
                    <a class="nav-link" href="pages-profile-user.html"><i class="bx bx-user me-1"></i> Profile</a>
                </li> -->
                <!-- <li class="nav-item">
                    <a class="nav-link" href="pages-profile-teams.html"><i class="bx bx-group me-1"></i> Teams</a>
                </li> -->
                <li class="nav-item">
                    <router-link class="nav-link" to="/project/create">
                        <i class="bx bx-grid-alt me-1"></i> {{ $t('project.button.create') }}
                    </router-link>
                </li>

                <!-- <li class="nav-item">
                    <a class="nav-link" href="pages-profile-connections.html"><i class="bx bx-link-alt me-1"></i>
                        Connections</a>
                </li> -->
            </ul>
            <div class="row g-4 mb-4">
                <div class="col-xl-4 col-lg-6 col-md-6" v-for="project, index in items" :key="index">
                    <project_index_item_box :post="project">
                    </project_index_item_box>
                </div>
            </div>
            <nav v-if="search.pages > 1">
                <ul class="pagination justify-content-center">
                    <li @click.prevent="clickPage(1)"><a class="page-link">
                            {{ $t('client.search.first') }}
                        </a></li>
                    <li class="page-item" :class="{ active: search.page == index }" @click.prevent="clickPage(index)"
                        v-for="index in search.pages" :key="index">
                        <a class="page-link" href="#"
                            v-if="search.page != index && ((index > Number(search.page) - 5 && index <= Number(search.page)) || (index < Number(search.page) + 5 && index >= Number(search.page)))">
                            {{ index }}
                        </a>
                        <span v-if="search.page == index" class="page-link">
                            {{ index }}
                        </span>
                    </li>
                    <li @click.prevent="clickPage(search.pages)"><a class="page-link">
                            {{ $t('client.search.last') }}
                        </a></li>
                </ul>
            </nav>
        </div>

        <!-- otwiera modal do skopiowania projektu -->
        <b-modal v-model="copyModal.show" title="Skopiuj projekt" @ok="copyModalSubmit" :ok-disabled="copyModal.isLoading">
            <form @submit="copyModalSubmit">
                <div class="form-group">
                    <label>Projekt</label>
                    <select v-model="copyModal.project_id" class="form-control"
                        :class="{ 'is-invalid': copyModal.validation.project_id != '' }">
                        <option value="0">Wybierz z listy</option>
                        <option v-for="item, index in itemsTemplate" :key="index" :value="item.project_id">
                            <template v-if="item.project_type == 'template'">
                                {{ item.project_name }}
                            </template>
                        </option>
                    </select>
                    <div class="invalid-feedback">{{ copyModal.validation.project_id }}</div>
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
import axios from '../../api/axios';
import MagicoModal from '../../components/MagicoModal.vue';
import ProjectIndexItemBox from './components/ProjectIndexItemBox.vue';
import api from '../../api/api';
export default
    {
        name: 'ProjectIndex',
        components: {
            'b-modal': MagicoModal,
            'project_index_item_box': ProjectIndexItemBox
        },
        props: ['module'],
        computed: {
            slownik_user: function () { return this.$store.state.slownik_user },
            slownik_contest: function () { return this.$store.state.slownik_contest },
            slownik_client: function () { return this.$store.state.slownik_client },
            slownik_project_settlements: function () { return this.$store.state.slownik_project_settlements },
            slownik_project_contract: function () { return this.$store.state.slownik_project_contract },
            slownik_project_parent_id: function () { return this.$store.state.slownik_project_parent_id },
        },
        data: function () {
            return {
                BASE_URL: api.getBaseUrl(),

                slownik_project_type: [{
                    name: 'Standardowe',
                    value: 'standard'
                }, {
                    name: 'Szablon',
                    value: 'template'
                }],
                slownik_project_status: [{
                    name: 'Aktywny',
                    value: 'enabled'
                }, {
                    name: 'Zakończony',
                    value: 'disabled'
                }],
                isLoading: true,
                items: [],
                itemsTemplate: [],
                search: {
                    page: 1,
                    limit: 100,
                    pages: 1,
                    client_id: '',
                    contest_id: '',
                    project_status: '',
                    project_name: '',
                    project_shortname: '',
                    project_type: 'standard',
                    project_settlements: '',
                    project_contract: '',
                    user_id: '',
                    project_parent_id: '',
                },
                copyModal: {
                    show: false,
                    isLoading: false,
                    project_id: 0,
                    validation: {
                        project_id: ''
                    },
                },
            }
        },
        methods: {
            getUserImage(user_id) {
                return this.$store.state.users.find(item => user_id == item.id)?.avatar;
            },
            getUserName(user_id) {
                return this.$store.state.users.find(item => user_id == item.id)?.username;
            },
            lang(x) { return x },
            convertSettlement(itemsString) {
                var vm = this;
                var ar = itemsString.split('|');
                this.slownik_project_settlements.forEach(i => {
                    vm.g_slownik_project_settlements[i.value] = false
                    ar.forEach(y => {
                        if (y == i.value) vm.g_slownik_project_settlements[i.value] = true;
                    })
                })
            },
            convertContract(itemsString) {
                var vm = this;
                var ar = itemsString.split('|');
                console.log(ar);
                this.slownik_project_contract.forEach(i => {
                    vm.g_slownik_contract[i.value] = false
                    ar.forEach(y => {
                        if (y == i.value) vm.g_slownik_contract[i.value] = true;
                    })
                })
            },
            copyModalSubmit: function (bvModalEvt) {
                bvModalEvt.preventDefault()
                this.copyModal.isLoading = true;
                this.copyModal.show = true
                this.copyProject();
            },
            copyModalOpen: function () {
                this.getPostsTemplate();
                this.copyModal.show = true
                this.copyModal.isLoading = false;
                this.copyModal.validation.project_id = '';
                this.copyModal.project_id = 0;
            },
            handleAxiosError: function (error) {
                if (error.response.data.messages) {
                    for (const property in error.response.data.messages) {
                        this.copyModal.validation[property] = error.response.data.messages[property];
                    }
                } else console.log(error);
                this.copyModal.isLoading = false;
            },
            copyProject: function () {
                var vm = this;
                axios.post(this.BASE_URL + 'api/v1/project/' + this.copyModal.project_id + '/copy', this.item).then(response => {
                    vm.copyModal.show = false;
                    this.$router.push('/edit/' + response.data.project_id);
                    this.copyModal.isLoading = false;
                    //vm.getPosts();
                }).catch(error => {
                    vm.handleAxiosError(error);
                });
            },
            getClient: function (id) {
                //moveitem
                var name = '';
                this.slownik_client.forEach(item => {
                    if (item.client_id == id) {
                        name = item.client_name;
                        return
                    }
                })
                return name;
            },
            getContest: function (id) {
                //moveitem
                var name = '';
                this.slownik_contest.forEach(item => {
                    if (item.contest_id == id) {
                        name = item.contest_name;
                        return
                    }
                })
                return name;
            },
            clickPage(page) {
                this.search.page = page;
                this.getPosts();
            },
            onDelete() {
                this.getPosts();
            },
            clickShow: function (item) {
                this.$router.push('/task/' + item.project_id);
            },
            clickEdit: function (item) {
                this.$router.push('/edit/' + item.project_id);
            },
            getPosts: function () {
                let vm = this;
                vm.isLoading = true;
                this.search.count_task = true;
                this.search.addUsers = 1;
                axios.get(this.BASE_URL + 'api/v1/project', {
                    params: this.search
                }).then(response => {
                    vm.items = response.data.data;
                    // response.data.data.forEach(item=>{
                    //     vm.items.push(item)
                    // })
                    vm.search.pages = response.data.pages;
                    vm.isLoading = false;
                });
            },
            getPostsTemplate: function () {
                //pobieranie listy projektów tylko szablonów do modala kopiowania
                let vm = this;
                axios.get(this.BASE_URL + 'api/v1/project', {
                    params: {
                        'project_type': 'template',
                        'project_module': this.module
                    }
                }).then(response => {
                    vm.itemsTemplate = response.data.data;
                });
            },
            submitSearchForm: function () {
                this.search.page = 1;
                this.getPosts();
            },
            resetSearchForm: function () {
                for (const property in this.search) {
                    this.search[property] = '';
                }
                this.getPosts();
            }
        },
        created() {
            this.search.project_module = this.module;
            if (this.$route.query.szablon) {
                this.search.project_type = 'template';
            } else {
                this.search.project_type = 'standard';
            }
            // alert(this.$route.query.szablon);
            if (this.$route.query && this.$route.query.contest) {
                this.search.contest_id = Number(this.$route.query.contest);
            }
            console.log('router', this.$route.query);
            // this.search.project_group = this.$route.params.slownik ? this.$route.params.slownik : '';
            this.getPosts();
        }
    }
</script>